import { Injectable } from '@angular/core';
import { catchError, from, of, Subject, switchMap, timeout } from 'rxjs';
import { CognitoUserPool } from './cognito-user-pool.interface';

@Injectable({
  providedIn: 'root',
})
export class CognitoUserPoolConfigService {
  public defaultConfig: CognitoUserPool = {
    // pulls from Semaphore Looky prod.env or non-prod.env config files
    user_pool_id: process.env.COGNITO_USER_POOL_ID,
    client_id: process.env.COGNITO_CLIENT_ID,
    oauth_domain: process.env.COGNITO_DOMAIN,
  };

  /* eslint-disable-next-line local-rules/no-ng-location */
  public userPoolUrl = `${location.origin}/anubis/v1/user-pool`;

  public userPoolConfig$: Subject<CognitoUserPool> = new Subject();

  public useFetchedUserPoolIds(timeoutIn = 1000): void {
    from(this.fetchUserPoolConfig())
      .pipe(
        // Waits 1 second before continuing with defualt values when in QA Environments.
        timeout(timeoutIn),
        switchMap((response: Response) => this.userPoolFromResponse(response)),
        catchError((error) => {
          // eslint-disable-next-line no-console
          console.error('configureUserPool/userPoolConfig$/error: ', error);
          return of(this.defaultConfig);
        })
      )
      .subscribe(
        ({ user_pool_id, client_id, oauth_domain }: CognitoUserPool) => {
          this.setUserPoolIds({ user_pool_id, client_id, oauth_domain });
        }
      );
  }

  /* setDefaultUserPoolIds - Defaults to Production Userpool*/
  public useDefaultUserPoolIds(): void {
    this.setUserPoolIds(this.defaultConfig);
  }

  public setUserPoolIds(data: CognitoUserPool): void {
    this.userPoolConfig$.next(data);
  }

  public fetchUserPoolConfig(): Promise<Response> {
    // eslint-disable-next-line no-console
    console.error(
      '>>> configureUserPool/fetchUserPoolConfig: ',
      this.userPoolUrl
    );
    return fetch(this.userPoolUrl, {
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
    });
  }

  private userPoolFromResponse(response: Response): Promise<CognitoUserPool> {
    // eslint-disable-next-line no-console
    console.error('>>> configureUserPool/userPoolFromResponse: ', response);
    return response.ok
      ? (response.json() as Promise<CognitoUserPool>)
      : Promise.reject('response not.ok');
  }
}
